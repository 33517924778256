import { useTranslation } from 'react-i18next'
import MultiOfferModal from './MultiOfferModal'
import { useWindowWidth } from '../../Hooks'
import { ProductListSections } from '../../../api/travel-search-config'
import { unlockScroll } from '../../Utils/scrolling'
import { Button, FerryReturnIcon } from '@dfds-frontend/compass-ui'
import clsx from 'clsx'

interface Props {
  offerModalShown: boolean
  route: string
  description?: string
  productLists: ProductListSections[]
  inView: boolean
  onProductClicked: (minibookId: string) => void
  onRequestOfferModal: (show: boolean) => void
}

const MultiOffer: React.FC<Props> = ({
  route,
  productLists,
  offerModalShown,
  onProductClicked,
  description,
  inView,
  onRequestOfferModal,
}) => {
  const { t } = useTranslation()
  const { widthSmallerThan1025 } = useWindowWidth()

  return (
    <>
      <div className={`ts-flex ts-bg-transparent ${inView ? 'ts-w-full' : 'ts-w-fit'}`}>
        {!widthSmallerThan1025 ? (
          <div className="ts-flex ts-w-full">
            <div
              className={`ts-border-solid ts-z-5000 ts-flex ${inView ? 'ts-w-full ts-bg-transparent' : 'ts-bg-dfds-surface-light/30 ts-px-dfds-s ts-py-dfds-xs'}`}
            >
              <div className={`ts-flex ts-w-full ts-gap-dfds-xs`}>
                <div className="ts-flex ts-w-full ts-gap-dfds-xs ts-flex-row ts-items-center ts-text-dfds-text-light-primary mobile:ts-w-full">
                  <FerryReturnIcon className={clsx(inView ? 'ts-text-cvi-brand-brand50' : 'ts-text-cvi-brand-brand900')} size="xl" />
                  <div className="ts-flex ts-flex-col">
                    {description ? (
                      <div className={clsx(inView ? 'ts-text-cvi-brand-brand50' : 'ts-text-cvi-brand-brand900')}>
                        <div className="ts-font-medium">{t('GENERAL-ROUTE')}</div>
                        <div>{route}</div>
                      </div>
                    ) : (
                      <div className="ts-text-cvi-brand-brand900 ts-font-medium ts-max-w-[100px]">{description}</div>
                    )}
                  </div>
                  <div className="ts-ml-auto dfds-orange-button">
                    <Button data-e2e="multiofferButton" size="md" onClick={() => onRequestOfferModal(true)}>
                      {t('SEE-OFFERS')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`ts-border-solid ts-h-0 ts-w-0 ts-border-b-[64px] ts-border-l-[36px] ts-border-t-[0px] ts-border-b-transparent ts-border-t-transparent ${
                inView ? 'ts-border-none' : 'ts-border-l-dfds-surface-light/30'
              }`}
            ></div>
          </div>
        ) : (
          <div data-e2e="multiofferButton" className="ts-font-medium md:ts-right-0 mobile:ts-left-0 dfds-orange-button">
            <Button size="md" onClick={() => onRequestOfferModal(true)}>
              {t('SEE-OFFERS')}
            </Button>
          </div>
        )}
      </div>
      <MultiOfferModal
        productLists={productLists}
        show={offerModalShown}
        onClose={() => {
          unlockScroll()
          onRequestOfferModal(false)
        }}
        onProductClicked={(id) => {
          unlockScroll()
          onProductClicked(id)
        }}
      />
    </>
  )
}

export default MultiOffer

export interface ProductList {
  description: string
  products: Product[]
}
export interface Product {
  title: string
  productDetails: string
  offerDetails: string
  minibookId: string
}
