'use client'

import clsx from 'clsx'
import { ReactNode } from 'react'

export type GridProps = {
  children: ReactNode
  gap?: 's' | 'l'
  surface?: 'inverted' | 'dark' | 'transparent'
  top?: 's' | 'l'
  bottom?: 's' | 'l'
}

export const Grid = ({ gap, surface, top, bottom, children }: GridProps) => {
  return (
    <>
      <div
        className={clsx(
          'ts-bg-dfds-surface-light ts-w-full',
          surface === 'inverted' && 'ts-bg-dfds-surface-tertiary',
          surface === 'dark' && 'ts-bg-dfds-surface-secondary',
          surface === 'transparent' && 'ts-bg-transparent'
        )}
      >
        <div
          className={clsx(
            'ts-grid ts-grid-cols-12 ts-gap-x-dfds-s',
            gap === 's' ? 'md:ts-gap-x-dfds-s' : 'md:ts-gap-x-dfds-l',
            top === 's' && 'ts-pt-dfds-s',
            top === 'l' && 'ts-pt-dfds-l',
            bottom === 's' && 'ts-pb-dfds-s',
            bottom === 'l' && 'ts-pb-dfds-l',
            'md:ts-mx-[40px]',
            'xl:ts-max-w-[1200px] xl:ts-mx-auto'
          )}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default Grid
