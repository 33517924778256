import { useTranslation } from 'react-i18next'
import { Button, ChevronLeftIcon } from '@dfds-frontend/compass-ui'
import clsx from 'clsx'

interface Props {
  isOneway: boolean
  onSelected: (oneway: boolean) => void
  isMultiOffer: boolean
  onMultiOfferClicked: () => void
}

const OnewayReturn: React.FC<Props> = ({ isOneway, onSelected, isMultiOffer, onMultiOfferClicked }) => {
  const { t } = useTranslation()

  return (
    <>
      {isMultiOffer && (
        <div className="ts-flex ts-flex-none ts-cursor-pointer ts-mb-dfds-xs ts-truncate [&>button]:!ts-outline-none">
          <Button data-e2e="multiOfferTab" onClick={() => onMultiOfferClicked()} rounded="none" size="md" variant="link">
            <ChevronLeftIcon size="lg" />
            {t('GENERAL-BACK-TO-OFFERS')}
          </Button>
        </div>
      )}
      <div className="ts-flex ts-flex-row ts-h-[44px] ts-border ts-border-cvi-grey-grey100 ts-rounded-[5px] ts-bg-white ts-p-dfds-xxs ts-w-fit">
        <div
          onClick={() => onSelected(false)}
          data-e2e="returnTab"
          data-e2e-selected={!isOneway}
          className={clsx(
            'ts-font-normal ts-flex-1  ts-text-sm ts-text-cvi-brand-brand900 ts-cursor-pointer ts-px-dfds-s',
            !isOneway && 'ts-bg-cvi-brand-brand100 ts-cursor-default'
          )}
        >
          <span className={clsx('ts-h-full ts-flex ts-items-center ts-rounded-[5px]')}>{t('RETURN')}</span>
        </div>
        <div
          onClick={() => onSelected(true)}
          data-e2e="oneWayTab"
          data-e2e-selected={isOneway}
          className={clsx(
            'ts-font-normal ts-flex-1 ts-text-sm ts-text-cvi-brand-brand900 ts-cursor-pointer ts-px-dfds-s',
            isOneway && 'ts-bg-cvi-brand-brand100 ts-cursor-default'
          )}
        >
          <span className={clsx('ts-h-full ts-flex ts-items-center ts-truncate ts-max-w-[200px] ts-rounded-[5px]')}>{t('ONE-WAY')}</span>
        </div>
      </div>
    </>
  )
}

export default OnewayReturn
