import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { Button } from '@dfds-frontend/compass-ui'
import { useRef } from 'react'
import Grid from './Shared/Grid'

interface Props {
  shown: boolean
  offersShown: boolean
  onClick: () => void
}

const StickyButton: React.FC<Props> = ({ shown, offersShown, onClick }) => {
  const { t } = useTranslation()

  const stickyRef = useRef<HTMLInputElement>(null)
  const height: number = stickyRef.current?.getBoundingClientRect().height ?? 0

  return (
    <>
      <motion.div
        ref={stickyRef}
        initial={{
          top: shown ? 0 : -height,
        }}
        animate={{
          top: shown ? 0 : -height,
        }}
        transition={{ type: 'spring', bounce: 0, duration: shown ? 0.3 : 0 }}
        className={`ts-fixed ts-left-0 ts-right-0 ts-top-0 ts-z-50 ts-flex ts-w-full ts-flex-row ts-bg-cvi-brand-brand900 lg:ts-hidden`}
      >
        {stickyRef.current && (
          <Grid surface="inverted">
            <div className="ts-col-span-12 ts-w-full">
              <div className="ts-py-dfds-s mobile:ts-px-dfds-s ts-ml-auto ts-w-fit dfds-orange-button">
                <Button size="lg" onClick={onClick} stretch>
                  {offersShown ? t('SEE-OFFERS') : t('SEE-PRICES')}
                </Button>
              </div>
            </div>
          </Grid>
        )}
      </motion.div>
    </>
  )
}

export default StickyButton
