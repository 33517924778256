import useSWR from 'swr'
import { fetcher, getBaseApi, getLocaleFromUrlOrQuery } from '../Utils/common'
import { UseRoutesResult } from './useRoutes'
import { UseVehiclesResult } from './useVehicles'
import { UseTravelDatesResult } from './useTravelDates'
import { format } from 'date-fns'
import { isValidOfferDomain } from '../Components/OfferField'
import { UrlSlug } from '../Utils/content'

const baseApi = getBaseApi()

export const useBookingUrl = (
  urlSlugs?: UrlSlug[],
  useRoutesResult?: UseRoutesResult,
  useVehiclesResult?: UseVehiclesResult,
  useTravelDates?: UseTravelDatesResult,
  currency?: string,
  offercode?: string
): UseBookingUrlResult => {
  const locale = getLocaleFromUrlOrQuery()

  let params = new URLSearchParams()

  const routeCode = useRoutesResult?.selectedRoute?.routeCode
  const vehicleCode = useVehiclesResult?.selectedVehicle?.code
  const trailerCode = useVehiclesResult?.selectedVehicle?.selectedTrailer?.code
  const multipleVehiclesChecked = useVehiclesResult?.multipleVehicles
  const vehicleCount = useVehiclesResult?.selectedVehicle?.count
  const adults = useVehiclesResult?.selectedPassengers?.adults ?? 1
  const children = useVehiclesResult?.selectedPassengers?.children
  const childAges = useVehiclesResult?.selectedPassengers?.childrenAges
  const pets = useVehiclesResult?.selectedPassengers?.pets
  const outboundDate = useTravelDates?.selectedOutboundDate
  const homeboundDate = useTravelDates?.selectedHomeboundDate
  let offerOrProductCode = useRoutesResult?.routesApiData?.offerOrProductCode

  // using offercode variable is only for localhost and none dfds domains except for app-travel-search
  if (!offerOrProductCode && isValidOfferDomain() && offercode != '') {
    offerOrProductCode = offercode
  }

  locale && params.append('locale', locale)
  routeCode && params.append('routeCode', routeCode)
  vehicleCode && params.append('vehicleCode', vehicleCode)
  outboundDate && params.append('outboundDate', format(outboundDate, 'yyyy-MM-dd'))
  params.append('isOneway', useRoutesResult?.isOneway ? 'true' : 'false')
  params.append('adults', adults.toString())
  offerOrProductCode && params.append('offerOrProductCode', offerOrProductCode ?? '')
  trailerCode && params.append('trailerCode', trailerCode)
  homeboundDate && params.append('homeboundDate', format(homeboundDate, 'yyyy-MM-dd'))
  children && params.append('children', children?.toString())
  childAges?.map((age: number) => params.append(`childAges`, age.toString()))
  pets && params.append('pets', pets.toString())
  currency && params.append('currency', currency)
  multipleVehiclesChecked && params.append('multipleVehicles', 'true')
  vehicleCount && params.append('numberOfVehicles', vehicleCount.toString())

  const url = `${baseApi}/booking-url?${params.toString()}`

  const { data, isLoading } = useSWR(url, { fetcher })

  const origin = window.location.origin
  const passengerFerriesUrlSlug = urlSlugs?.find((urlSlug) => urlSlug.locale === locale) // only reason for this is that when on the very root of each locale i.e. dfds.com/en-gb the /passenger-ferries part (which is localized) is missing and is needed by both sbw and paxob when generating the link

  const dfdsUrl = isVercelUrl(window.location.href)
    ? `${isVercelUrl(window.location.href)}/${locale}/${passengerFerriesUrlSlug?.slug}/${data?.url}`
    : `${origin}/${locale}/${passengerFerriesUrlSlug?.slug}/${data?.url}`

  return {
    isLoading,
    url: addSrcParamIfItExists(window.location.href, data?.isFrsRoute ? data.url : dfdsUrl),
  } as UseBookingUrlResult
}

export interface UseBookingUrlResult {
  isLoading: boolean
  url: string
}

const isVercelUrl = (url: string): string | undefined => {
  if (url.includes('localhost:5001')) return 'https://localhost:5001'
  if (url.includes('travel-search-dev')) return 'https://www.dev.dfds.com'
  //Forward App nonprod to test as we always pushes PR to www.test.dfds.com
  else if (url.includes('app-travel-search-nonprod') || url.includes('pax-travel-search') || url.includes('travel-search-test'))
    return 'https://www.test.dfds.com'
  else if (url.includes('travel-search-uat')) return 'https://www.accept.dfds.com'
  else if (url.includes('app-travel-search-prod') || url.includes('travel-search-prod')) return 'https://www.dfds.com'
  else return undefined
}

function addSrcParamIfItExists(srcUrl: string, targetUrl: string): string {
  const srcUrlObj = new URL(srcUrl)
  const targetUrlObj = new URL(targetUrl)

  const srcParam = srcUrlObj.searchParams.get('utm_source')
  if (srcParam) {
    targetUrlObj.searchParams.set('utm_source', srcParam)
  }

  return targetUrlObj.toString()
}
