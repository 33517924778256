import { CloseIcon } from '@dfds-frontend/compass-ui'

export type ModalHeaderProps = {
  children: string
  closeAble: boolean
  onCloseRequested: () => void
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ children, closeAble, onCloseRequested }) => {
  return (
    <div
      data-e2e="modal-header"
      className="ts-border-b ts-border-solid ts-border-gray-300 ts-flex ts-h-[64px] ts-min-h-[64px] ts-flex-row ts-items-center ts-bg-dfds-surface-light ts-pl-dfds-s"
    >
      <label className="ts-max-w-[350px] ts-text-xl ts-font-medium ts-text-cvi-brand-brand900">{children}</label>
      {closeAble && (
        <div
          className="ts-fill-bg-500 ts-ml-auto ts-flex ts-h-full ts-w-12 ts-cursor-pointer ts-items-center ts-justify-center hover:ts-text-cvi-brand-brand500"
          onClick={onCloseRequested}
        >
          <CloseIcon size="lg" />
        </div>
      )}
    </div>
  )
}

export default ModalHeader
