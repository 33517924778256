import { RefObject, useEffect, useRef } from 'react'
import OverlayDateFooter from './OverlayDateFooter'
import { toShortDateString } from '../../Utils/date'
import { useTranslation } from 'react-i18next'
import { useWindowWidth2 } from '../../Hooks/useWindowWidth2'
import { useThreeDots } from '../../Hooks/useThreeDots'
import { CalendarOutboundIcon, CalendarReturnIcon, ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@dfds-frontend/compass-ui'
import clsx from 'clsx'

interface Props {
  showFooter: boolean
  isOneway: boolean
  isOpen: boolean
  fieldName: string
  returnFocused: boolean
  selectedOutboundDate: Date | undefined
  selectedHomeboundDate?: Date | undefined
  isLoading: boolean
  children: React.ReactNode
  dataAttrList: { name: string; value?: string }[]
  containerRef: RefObject<HTMLDivElement>
  disabled?: boolean
  onClick: (clickedOutside: boolean, clickedReturnField?: boolean) => void
  onClose: () => void
}

const DateField: React.FC<Props> = ({
  isOneway,
  isOpen,
  fieldName,
  selectedOutboundDate,
  selectedHomeboundDate,
  isLoading,
  children,
  dataAttrList,
  disabled,
  onClick,
  onClose,
}) => {
  const { t } = useTranslation()

  const threeDots = useThreeDots()
  const { isMobile, isTablet } = useWindowWidth2()
  const ref = useRef<HTMLDivElement>(null)

  let dataAttrProps = {} as ComponentProps
  dataAttrList.map((dataAttr) => {
    const key = `data-e2e-${dataAttr?.name}`
    const dataAttrKey = key as keyof ComponentProps
    dataAttrProps[dataAttrKey] = dataAttr.value?.toString() ?? ''
  })
  const keyLoading = `data-e2e-${fieldName}-loading`
  const dataAttrKeyLoading = keyLoading as keyof ComponentProps
  dataAttrProps[dataAttrKeyLoading] = isLoading.toString()

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!(event?.target?.name === 'day' && event?.target?.type === 'button')) {
          // This is a hack to prevent the datepicker from closing when clicking on a day in the calendar on offers because a rerender happens when homebound dates are loaded
          onClick(true)
        }
      }
    }

    if (isOpen && !isMobile && !isTablet && ref) addEventListener('click', handleClickOutside)
    if (!isOpen && ref) window.removeEventListener('click', handleClickOutside)
    return () => window.removeEventListener('click', handleClickOutside)
  }, [isOpen, ref])

  return (
    <>
      <div
        ref={ref}
        className="ts-relative ts-h-[58px] ts-flex ts-flex-row ts-gap-dfds-xs lg:ts-gap-0"
        id={`${fieldName}-field-container`}
        {...dataAttrProps}
      >
        <div
          className={clsx(
            'ts-bg-white ts-w-full ts-h-full ts-flex ts-flex-row  ts-gap-dfds-xs ts-items-center ts-px-dfds-xs ts-border ts-border-cvi-grey-grey100 lg:ts-border-r-0',
            disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
          )}
          onClick={() => {
            if (!isOpen && !isLoading && !disabled) {
              onClick(false)
              document.getElementById(`${fieldName}-input`)?.focus()
            } else onClose()
          }}
        >
          <div className="ts-text-cvi-brand-brand900">
            <CalendarOutboundIcon size="md" />
          </div>
          <div className="ts-truncate ts-flex ts-flex-col ts-justify-center">
            <label className="ts-text-cvi-grey-grey700 ts-text-xs ts-cursor-pointer"> {t('OUTBOUND')}</label>
            <input
              data-e2e="outbound-date-input"
              readOnly
              value={isLoading ? threeDots : toShortDateString(selectedOutboundDate)}
              id={`${fieldName}-input`}
              className={clsx(
                'ts-w-full ts-flex-none ts-border-none ts-font-medium ts-text-cvi-brand-brand900 ts-leading-none ts-select-none focus:ts-outline-none ts-truncate',
                disabled ? 'ts-text-dfds-text-dark-disabled ts-cursor-not-allowed' : 'ts-cursor-pointer'
              )}
            />
          </div>
          <div className="ts-ml-auto">{isOpen ? <ChevronUpIcon size="md" /> : <ChevronDownIcon size="md" />}</div>
        </div>
        <div
          className={clsx(
            'ts-bg-white ts-w-full ts-h-full ts-flex ts-flex-row ts-gap-dfds-xs ts-items-center ts-px-dfds-xs ts-border ts-border-cvi-grey-grey100 lg:ts-border-r-0',
            isOneway ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
          )}
          onClick={() => {
            if (!isOpen && !isLoading && !disabled) {
              onClick(false, true)
              document.getElementById(`${fieldName}-input`)?.focus()
            } else if (!isOneway) onClose()
          }}
        >
          <div className={clsx('ts-text-cvi-brand-brand900', isOneway && 'ts-opacity-30')}>
            <CalendarReturnIcon size="md" />
          </div>
          <div className={clsx('ts-truncate ts-flex ts-flex-col ts-justify-center', isOneway && 'ts-opacity-30')}>
            <label className="ts-text-cvi-grey-grey700 ts-text-xs ts-cursor-pointer">{t('INBOUND')}</label>
            <input
              data-e2e="homebound-date-input"
              readOnly
              value={isLoading && !isOneway ? threeDots : toShortDateString(selectedHomeboundDate)}
              id={`${fieldName}-input`}
              className={clsx(
                'ts-w-full ts-flex-none ts-border-none ts-font-medium ts-text-cvi-brand-brand900 ts-leading-none ts-select-none focus:ts-outline-none ts-truncate',
                disabled ? 'ts-text-dfds-text-dark-disabled ts-cursor-not-allowed' : 'ts-cursor-pointer'
              )}
            />
          </div>
          <div className={clsx('ts-ml-auto', isOneway && 'ts-opacity-30')}>
            {isOpen ? <ChevronUpIcon size="md" /> : <ChevronDownIcon size="md" />}
          </div>
        </div>
        <div
          className={clsx(
            `ts-z-modal ts-bg-white lg:ts-absolute lg:ts-top-[58px] lg:ts-mt-dfds-xs lg:ts-bg-white lg:ts-shadow-md lg:ts-drop-shadow-xl tabletDown:ts-fixed tabletDown:ts-bottom-0 tabletDown:ts-left-0 tabletDown:ts-right-0 tabletDown:ts-top-0 tabletDown:ts-w-full`,
            !isOpen && 'ts-invisible',
            isOpen && 'ts-left-[-250px]'
          )}
        >
          <div className="ts-flex ts-h-full ts-flex-col lg:ts-p-dfds-s">
            <div id="header-content" className="ts-flex ts-h-[64px] ts-min-h-[64px] ts-flex-row ts-items-center ts-pl-dfds-s lg:ts-hidden">
              <label className="ts-max-w-[350px] ts-text-xl ts-font-medium ts-text-cvi-brand-brand900">{t('SELECT-DATE')}</label>
              <div
                className={`ts-fill-bg-500 ts-ml-auto ts-flex ts-h-full ts-w-12 ${
                  isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
                } ts-items-center ts-justify-center hover:ts-text-cvi-brand-brand500`}
                onClick={() => {
                  if (!disabled) {
                    onClick(true)
                  }
                }}
              >
                <CloseIcon size="lg" />
              </div>
            </div>
            <div className="ts-border-b ts-border-solid ts-border-gray-300 lg:ts-hidden"></div>
            <div id="main-content" className="lg:ts-bg-white tabletDown:ts-h-[calc(100%-128px)] tabletDown:ts-overflow-y-scroll">
              {children}
            </div>
            {selectedOutboundDate && (
              <OverlayDateFooter
                isLoading={isLoading}
                isOneway={isOneway}
                outboundDate={selectedOutboundDate}
                homeboundDate={selectedHomeboundDate}
                onClick={() => onClick(false)}
              >
                <div className="ts-flex ts-flex-row ts-items-center"></div>
              </OverlayDateFooter>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DateField

interface ComponentProps {
  dataAttrName: string
  dataAttrValue: string
}
