export const isMultipleVehclesSupportedWithRoute = (routeCode: string): boolean => {
  // Routes
  if (routeCode === 'DVCA') return false
  if (routeCode === 'DVDK') return false
  if (routeCode === 'DKDV') return false
  if (routeCode === 'CADV') return false
  if (routeCode === 'DPNH') return false
  if (routeCode === 'NHDP') return false
  return true
}

export const isMultipleVehclesSupportedWithVehicleType = (routeCode: string): boolean => {
  // Vehicles
  if (routeCode === 'NCAR') return false
  return true
}

export const getMultipleVehicleSelectedCount = (count: number) => {
  if (!count) return ''
  if (count < 2) return ''
  if (count > 1) return `${count.toString()} `
}
