import { MinusIcon, PlusIcon } from '@dfds-frontend/compass-ui'
import clsx from 'clsx'
import { forwardRef } from 'react'

interface CounterProps {
  title?: string
  description?: string
  defaultValue: number
  downDisabled: boolean
  upDisabled: boolean
  ageRange?: string
  e2eAttribute: string
  onValueIncrease: () => void
  onValueDecrease: () => void
}

export const Counter = forwardRef<HTMLInputElement, CounterProps>(
  (
    { title, description, defaultValue, downDisabled, upDisabled, ageRange, e2eAttribute, onValueDecrease, onValueIncrease, ...rest },
    ref
  ) => {
    return (
      <div className="ts-inline-flex">
        <div className="ts-flex ts-flex-row ts-items-center">
          <button
            data-e2e={`${e2eAttribute}-down`}
            disabled={downDisabled}
            className={clsx(
              'ts-text-white ts-rounded-full ts-inline-flex ts-h-dfds-l ts-w-dfds-l ts-items-center ts-justify-center',
              downDisabled ? 'ts-bg-cvi-grey-grey100' : 'ts-bg-cvi-brand-brand900'
            )}
            onClick={onValueDecrease}
          >
            <MinusIcon size="md" className={downDisabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} />
          </button>
          <input
            ref={ref}
            {...rest}
            disabled={true}
            data-e2e={`${e2eAttribute}-input`}
            className="lg:ts-mx-dfds-xs ts-h-[40px] ts-border-none ts-w-[40px] ts-appearance-none ts-border ts-border-dfds-text-dark-primary ts-bg-transparent ts-text-center ts-text-cvi-brand-brand900 ts-font-medium tabletDown:ts-mx-dfds-xs"
            type="number"
            defaultValue={defaultValue}
          />
          <button
            data-e2e={`${e2eAttribute}-up`}
            disabled={upDisabled}
            className={clsx(
              'ts-text-white ts-rounded-full ts-inline-flex ts-h-dfds-l ts-w-dfds-l ts-items-center ts-justify-center focus:ts-border-cvi-brand-brand500',
              upDisabled ? 'ts-bg-cvi-grey-grey100' : ' ts-bg-cvi-brand-brand900'
            )}
            onClick={onValueIncrease}
          >
            <PlusIcon size="md" className={upDisabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} />
          </button>
        </div>
      </div>
    )
  }
)

export default Counter
