import { Height, Length } from '@dfds-pax/ui'
import React from 'react'

interface Props {
  height?: number
  length?: number
  children?: React.ReactNode
}

const Dimensions: React.FC<Props> = ({ height, length, children }) => {
  return (
    <>
      <div className="ts-flex ts-items-center">
        <Height />
        <div className="ts-text-cvi-brand-brand900 ts-text-[10px]">{height}m</div>
      </div>
      <div>{children}</div>
      <div className="ts-flex ts-items-center">
        <Length />
        <div className="ts-text-cvi-brand-brand900 ts-text-[10px]">{length}m</div>
      </div>
    </>
  )
}

export default Dimensions
