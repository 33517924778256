import { useTranslation } from 'react-i18next'
import { YesIcon } from '@dfds-frontend/compass-ui'
import clsx from 'clsx'
import { isMobile, isTablet } from 'react-device-detect'

interface Props {
  selectedCurrency: 'EUR' | 'MAD'
  onSelectCurrency: (currency: 'EUR' | 'MAD') => void
}

const Currency: React.FC<Props> = ({ selectedCurrency, onSelectCurrency }) => {
  const { t } = useTranslation()

  return (
    <div className="ts-bg-dfds-surface-light">
      <ul className="ts-border-0 ts-border-solid ts-list-none ts-p-0 ts-m-0 ts-w-full">
        <li
          data-e2e={`currency-euro}`}
          key={'euro'}
          className={clsx(
            `ts-list-none ts-p-0 ts-w-full ts-m-0 ts-flex ts-h-[90px] ts-border-solid ts-border-0 ts-cursor-pointer ts-items-center ts-px-dfds-s ts-border-b ts-border-b-dfds-divider-dark
              `,
            selectedCurrency === 'EUR'
              ? 'ts-bg-cvi-states-selected'
              : `ts-bg-dfds-surface-light ${!isMobile && !isTablet && 'hover:ts-bg-cvi-states-hover'}`
          )}
          onClick={() => onSelectCurrency('EUR')}
        >
          <label className="ts-text-cvi-brand-brand900 ts-font-medium ts-cursor-pointer">{t('EURO')}</label>
          {selectedCurrency === 'EUR' && (
            <div className="ts-ml-auto ts-text-cvi-brand-brand900">
              <YesIcon size="md" />
            </div>
          )}
        </li>
        <li
          data-e2e={`currency-dirham}`}
          key={'dirham'}
          className={clsx(
            `ts-list-none ts-p-0 ts-m-0 ts-flex ts-h-[90px] ts-border-solid ts-border-b ts-cursor-pointer ts-items-center ts-border-b-dfds-divider-dark ts-px-dfds-s
              `,
            selectedCurrency === 'MAD'
              ? 'ts-bg-cvi-states-selected'
              : `ts-bg-dfds-surface-light ${!isMobile && !isTablet && 'hover:ts-bg-cvi-states-hover'}`
          )}
          onClick={() => onSelectCurrency('MAD')}
        >
          <label className="ts-text-cvi-brand-brand900 ts-font-medium ts-cursor-pointer">{t('DIRHAM')}</label>
          {selectedCurrency === 'MAD' && (
            <div className="ts-ml-auto ts-text-cvi-brand-brand900">
              <YesIcon size="md" />
            </div>
          )}
        </li>
      </ul>
    </div>
  )
}

export default Currency
